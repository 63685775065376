
import { Options, Vue } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import { Device } from '@/store/types'
import { AppActions } from '@/store/module/app'
import Cookies from 'js-cookie'
import { EventBuss, Token } from '@/utils/constants'
import { setCookieValue } from '@/api'

@Options({})
export default class ServerLinePage extends Vue {
    @Getter('device') device!: Device

    @Action(AppActions.PING_SERVER) pingApi!: (_params: any) => Promise<any>
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void

    serverStr = ''
    line1 = false
    line2 = false
    line3 = false

    toastMessage = ''
    timeoutMessage: number | boolean = false

    beforeMount() {
        window.sessionStorage.clear()
    }

    mounted() {
        this.pingServer(1)
        this.pingServer(2)
        this.pingServer(3)
    }

    clickLine(line: number): void {
        if (line === 1 && !this.line1) return
        else if (line === 2 && !this.line2) return
        else if (line === 3 && !this.line3) return

        setCookieValue(Token.LINE, String(line))
        this.$router.push({ name: 'login' })
    }

    visitCustumerService(): void {
        window.open(process.env.CUSTUMER_SERVICE, '_blank')
    }

    isValidSevice = (urlStr: string): boolean => {
        const pattern = new RegExp(
            '^((ft|htt)ps?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?' + // port
                '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
                '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        )
        return pattern.test(urlStr)
    }

    enterLogin(): void {
        if (this.serverStr.length > 0) {
            let url = this.serverStr.replace(/\/$/, '')

            let appendHttp = true

            if (url.startsWith('https://')) {
                appendHttp = false
            } else if (url.startsWith('http://')) {
                appendHttp = false
            }

            if (appendHttp) url = `http://${url}`

            if (!this.isValidSevice(url)) {
                this.toastMessage = 'ip 地址错误'
                this.timeoutMessage = setTimeout(() => {
                    this.toastMessage = ''
                    if (typeof this.timeoutMessage === 'number') {
                        clearTimeout(this.timeoutMessage)
                        this.timeoutMessage = false
                    }
                }, 6000)
                return
            }

            this.showLoading()

            this.pingApi({ line: -1, url })
                .then((response) => {
                    this.hideLoading()
                    if (response === 'test ok') {
                        Cookies.set(Token.LINE, '-1', { sameSite: 'strict' })
                        Cookies.set(Token.LINE_CUSTOM, url, {
                            sameSite: 'strict'
                        })

                        this.$router.push({ name: 'login' })
                    } else {
                        this.toastMessage = 'ip 地址错误'
                        this.timeoutMessage = setTimeout(() => {
                            this.toastMessage = ''
                            if (typeof this.timeoutMessage === 'number') {
                                clearTimeout(this.timeoutMessage)
                                this.timeoutMessage = false
                            }
                        }, 6000)
                    }
                })
                .catch(() => {
                    this.hideLoading()
                    this.toastMessage = 'ip 地址错误'
                    this.timeoutMessage = setTimeout(() => {
                        this.toastMessage = ''
                        if (typeof this.timeoutMessage === 'number') {
                            clearTimeout(this.timeoutMessage)
                            this.timeoutMessage = false
                        }
                    }, 6000)
                })
        }
    }

    async pingServer(_line: number) {
        const params = { line: _line, url: '' }

        this.pingApi(params)
            .then((response) => {
                if (response === 'test ok') {
                    if (_line === 1) this.line1 = true
                    else if (_line === 2) this.line2 = true
                    else if (_line === 3) this.line3 = true
                } else {
                    if (_line === 1) this.line1 = false
                    else if (_line === 2) this.line2 = false
                    else if (_line === 3) this.line3 = false
                }
            })
            .catch((e) => {
                // console.log(e)
                if (_line === 1) this.line1 = false
                else if (_line === 2) this.line2 = false
                else if (_line === 3) this.line3 = false
            })
    }
}
